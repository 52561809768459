import { resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-bfe62e3a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "header" }
const _hoisted_2 = { class: "header__mobile-alert" }
const _hoisted_3 = { class: "header__right-block" }
const _hoisted_4 = { class: "header__email" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MobileAlert = _resolveComponent("MobileAlert")!
  const _component_Logo = _resolveComponent("Logo")!
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_MobileAlert),
      _createTextVNode(" Please turn the screen over ")
    ]),
    _createVNode(_component_router_link, {
      to: "/admin/instructors",
      class: "header__logo"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_Logo)
      ]),
      _: 1
    }),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("div", _hoisted_4, _toDisplayString(this.email), 1),
      _createElementVNode("div", {
        class: "header__logout",
        onClick: _cache[0] || (_cache[0] = ($event: any) => (this.logout()))
      }, " Log out ")
    ])
  ]))
}